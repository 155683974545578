<template>
    <div class="tipeee-plugin-button-widget">
        <a :href="getProjectUrl" target="_blank" class="tipeee-plugin-classic-button">tip!</a>
        <div class="tipeee-plugin-button-tippers">
            <span class="tipeee-plugin-primary-text">{{ ProjectService.getParameter(project, 'tipperNumber') | formatNumber }}</span>&nbsp;{{ 'tipeur(s)' | trans({"smart_count": ProjectService.getParameter(project, 'tipperNumber', 0)})}}
        </div>
    </div>
</template>
<script>
import ProjectMixin from '@/plugins/projectMixin'

export default {
    mixins: [ProjectMixin],
    computed: {
        projectCaption () {
            return this.$options.filters.trans('Soutenez %creator% sur Tipeee', {
                creator: `<span class="tipeee-plugin-primary-text">${this.$options.filters.entityTrans(this.project, 'name')}</span>`
            })
        }
    }
}
</script>