class Project {
    static getParameter (project, key, fallback = null) {
        return (project && project.parameters && project.parameters[key]) ? project.parameters[key] : fallback
    }
    static isPerProject (project) {
        return this.getParameter(project, 'campaign_type', 'per_month') === 'per_project'
    }
    static isPerMonth (project) {
        return this.getParameter(project, 'campaign_type', 'per_month') === 'per_month'
    }
    static isPerContent (project) {
        return this.getParameter(project, 'campaign_type', 'per_month') === 'per_content'
    }
    static isPerPresale (project) {
        return this.isPerProject(project) && this.getParameter(project, 'campaign_goal_type', 'amount') === 'presale'
    }
    static getGoals (project) {
        if (!project) {
            return []
        }
        return project.goals.slice().sort((a, b) => (b.completed - a.completed))
    }
    static getCurrentGoal (project) {
        if (!project || !this.getGoals(project)) {
            return null
        }
        for (let index in this.getGoals(project)) {
            if (this.getGoals(project)[index].completed < 100) {
                return this.getGoals(project)[index]
            }
        }
    }
    static isExecutionDatePassed (project) {
        if (project && project.campaign && project.campaign.isExecutionDatePassed !== undefined) {
            return project.campaign.isExecutionDatePassed
        } else {
            return null
        }
    }
}

export default Project