<template>
    <div class="tipeee-plugin-box-padding tipeee-plugin-box-widget">
        <div class="tipeee-plugin-radius-block tipeee-plugin-box-container">
            <div class="tipeee-plugin-box-header tipeee-plugin-global-padding" :class="`tipeee-plugin-background-${backgroundColor}`">
                <div
                    class="tipeee-plugin-avatar tipeee-plugin-box-avatar"
                    :style="{ 'background-image': `url(${$options.filters.mediaCache(project.avatar ? project.avatar : 0, {width: 180, height: 180, avatar: project.id})})` }"
                ></div>
                <h1 class="tipeee-plugin-white-text">{{ project | entityTrans('name') }}</h1>
            </div>
            <div class="tipeee-plugin-box-content tipeee-plugin-background-white tipeee-plugin-global-padding">
                <div class="tipeee-plugin-box-amount" v-if="!(ProjectService.getParameter(project, 'hidedAmount', false) === '1')">
                    <template v-if="ProjectService.isPerPresale(project)">
                        <span class="tipeee-plugin-amount-value tipeee-plugin-primary-text tipeee-plugin-system-text">
                            {{ ProjectService.getParameter(project, 'tipperAmount') | formatNumber }}
                        </span>
                        {{ "prévente(s)" | trans({ "smart_count": ProjectService.getParameter(project, 'tipperAmount') }) }} &nbsp;
                    </template>
                    <template v-else>
                        <span class="tipeee-plugin-amount-value tipeee-plugin-primary-text tipeee-plugin-system-text">
                            {{ ProjectService.getParameter(project, 'tipperAmount') | formatNumber('currency', 'EUR') }}
                        </span>
                    </template>
                    <div class="tipeee-plugin-amount-label" v-html="collectedString"></div>
                </div>
                <GoalProgress v-if="ProjectService.isPerProject(project)" :project="project"/>
                <div class="tipeee-plugin-box-buttons">
                    <a :href="getProjectUrl" class="tipeee-plugin-button-tip" target="_blank">tip!</a>
                    <div class="tipeee-plugin-box-tipper-number tipeee-plugin-small-padding tipeee-plugin-background-grey tipeee-plugin-radius-small">
                        <span class="tipeee-plugin-tipper-value">{{ ProjectService.getParameter(project, 'tipperNumber') | formatNumber }}</span> {{ 'tipeur(s)' | trans({"smart_count": ProjectService.getParameter(project, 'tipperNumber', 0)})}}
                    </div>
                </div>
                <div class="tipeee-plugin-box-tippers" v-if="mode === 'tippers'">
                    <div v-for="tipper in tippers" :key="tipper.id">
                        <img :src="$options.filters.mediaCache(tipper.avatar ? tipper.avatar : 0, {width: 90, height: 90, avatar: tipper.id})" :title="tipper.pseudo" />
                    </div>
                </div>
                <div class="tipeee-plugin-box-goal tipeee-plugin-small-padding" v-else-if="goalMode">
                    <div class="tipeee-plugin-goal-progress">
                        <div class="tipeee-plugin-goal-gauge">
                            <div class="tipeee-plugin-gauge-fill" :style="{width: ProjectService.getCurrentGoal(project).completed > 100 ? 100 : ProjectService.getCurrentGoal(project).completed + '%'}"></div>
                        </div>
                        <div class="tipeee-plugin-goal-percent">{{ ProjectService.getCurrentGoal(project).completed > 100 ? 100 : ProjectService.getCurrentGoal(project).completed }}&nbsp;%</div>
                    </div>
                    <h3 class="tipeee-plugin-goal-title">{{ ProjectService.getCurrentGoal(project) | entityTrans('name')}}</h3>
                    <div class="tipeee-plugin-goal-desc" v-if="$options.filters.entityTrans(ProjectService.getCurrentGoal(project), 'description_v2')" v-html="$options.filters.entityTrans(ProjectService.getCurrentGoal(project), 'description_v2')" key="description_v2"></div>
                    <div class="tipeee-plugin-goal-desc" v-else key="description_v1">{{ ProjectService.getCurrentGoal(project) | entityTrans('description') }}</div>
                </div>
            </div>
            <div class="tipeee-plugin-box-footer tipeee-plugin-background-white tipeee-plugin-global-padding" :class="{'tipeee-plugin-box-footer-no-border': goalMode}">
                {{ 'Soutenez les créateurs du web sur' | trans }} <a :href="getSiteUrl" target="_blank"><img class="tipeee-plugin-logo-footer" :src="tipeeeLogo" /></a>
            </div>
        </div>
    </div>
</template>
<script>
import ProjectMixin from '@/plugins/projectMixin'
import tipeeeLogo from '@/assets/img/tipeee-logo.svg'
import GoalProgress from '@/components/elements/goalProgress.vue'

export default {
    mixins: [ProjectMixin],
    components: { GoalProgress },
    data: () => ({
        tipeeeLogo: tipeeeLogo
    }),
    computed: {
        backgroundColor () {
            return ['red', 'blue', 'yellow'].includes(this.color) ? this.color : 'red'
        },
        backgroundStyle () {
            switch (this.backgroundColor) {
                case 'red':
                    return `background-image: url('${this.getSiteUrl}/_static/img/patterns/confettis-moyen-rouge.png')`
                break
                case 'blue':
                    return `background-image: url('${this.getSiteUrl}/_static/img/patterns/confettis-moyen-rouge.png')`
                break
                case 'yellow':
                break
            }
        },
        goalMode () {
            return this.mode === 'goal' && this.ProjectService.getCurrentGoal(this.project)
        }
    } 
}
</script>