<template>
    <div class="t-per-project-goal-progress-block">
        <div class="t-per-project-goal-progress-container" :class="{ 'goal-completed': goalCompletion >= 100 }">
            <div class="t-per-project-goal-progress-item"></div>
            <span class="t-per-project-goal-progress-step" v-if="showProgressStep" :style="progressStep">
                <i class="fas fa-check"></i>
            </span>
            <div class="t-per-project-goal-progress-empty" :style="emptyProgressWidth"></div>
        </div>
        <span class="t-per-project-goal-progress-percent system-txt" :class="goalCompletionClass">
            {{ goalCompletion }}%
        </span>
    </div>
</template>

<script>
import Project from "@/plugins/lib/project"

export default {
    props: ["project"],
    data: () => ({
        emptyProgressWidth: {width: '100%'},
        progressBarFakePercent: 10
    }),
    computed: {
        campaignGoal () {
            if (Project.isPerPresale(this.project)) {
                return parseInt(Project.getParameter(this.project, 'campaign_goal_presale', 0))
            } else {
                return parseInt(Project.getParameter(this.project, 'campaign_goal', 0))
            }
        },
        tipperAmount () {
            return parseInt(Project.getParameter(this.project, 'tipperAmount', 0))
        },
        goalCompletion () {
            return this.campaignGoal ? Math.floor((this.tipperAmount / this.campaignGoal) * 100) : 0
        },
        goalCompletionClass () {
            let percentCat = Math.floor(this.goalCompletion / 5)
            return `t-per-project-goal-progress-percent-${percentCat}`
        },
        progressStep() {
            const fullWidthPercent = this.goalCompletion > 100 ? 101 : 100;
            const progressBarWidthPercent = fullWidthPercent + this.progressBarFakePercent
            const stepPercent = 100

            const percent = Math.min(100, progressBarWidthPercent - ((stepPercent / this.goalCompletion) * 100))

            return {
                'right': `${percent}%`,
                'transform': `translate(${percent}%, -50%)`
            }
        },
        showProgressStep() {
            if (this.project === null) {
                return false
            }
            return this.goalCompletion >= 100 && !Project.isExecutionDatePassed(this.project)
        }
    },
    mounted () {
        if (this.goalCompletion >= 100) {
            if (this.showProgressStep) {
                this.emptyProgressWidth.width = `${this.progressBarFakePercent}%`
            } else {
                this.emptyProgressWidth.width = '0%'
            }
        } else {
            this.emptyProgressWidth.width = (100 - this.goalCompletion) + '%'
        }
    }
}
</script>