<template>
    <div class="tipeee-plugin-radius-block tipeee-plugin-small-padding tipeee-plugin-background-white tipeee-plugin-small-card-widget">
        <a :href="getProjectUrl" target="_blank" class="tipeee-plugin-classic-button">tip!</a>
        <div class="tipeee-plugin-card-caption" v-html="projectCaption"></div>
    </div>
</template>
<script>
import ProjectMixin from '@/plugins/projectMixin'

export default {
    mixins: [ProjectMixin],
    computed: {
        projectCaption () {
            return this.$options.filters.trans('Soutenez %creator% sur Tipeee', {
                creator: `<span class="tipeee-plugin-primary-text">${this.$options.filters.entityTrans(this.project, 'name')}</span>`
            })
        }
    }
}
</script>