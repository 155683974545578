import Project from "@/plugins/lib/project"

export default {
    props: ['project', 'tippers', 'lang', 'mode', 'color', 'dataset'],
    computed: {
        ProjectService () {
            return Project
        },
        getProjectUrl () {
            return `https://${this.lang}.${process.env.TIPEEE_BASE_DNS}/${this.project.slug}`
        },
        projectContent () {
            if (Project.isPerPresale(this.project)) {
                return this.$options.filters.formatNumber(Project.getParameter(this.project, 'campaign_goal_presale', 0))
            } else if (Project.isPerProject(this.project)) {
                return this.$options.filters.formatNumber(Project.getParameter(this.project, 'campaign_goal', 0), 'currency', 'EUR')
            } else {
                return Project.isPerMonth(this.project) ? this.$options.filters.trans('mois') : Project.getParameter(this.project, 'campaign_name')
            }
        },
        collectedString () {
            let tradKey = 'collectés par %content%'
            if (this.mini) {
                tradKey = 'par %content%'
            }
            if (Project.isPerProject(this.project) || Project.isPerPresale(this.project)) {
                tradKey = this.mini ? 'sur %content%' : 'collectés sur %content%'
            }
            return this.$options.filters.trans(tradKey, {
                content: `<span class="t-primary-text">${this.projectContent}</span>`
            })
        },
        getSiteUrl () {
            return `https://${this.lang}.${process.env.TIPEEE_BASE_DNS}`
        },
        getLogoSrc () {
            return `https://${this.lang}.${process.env.TIPEEE_BASE_DNS}/img/tipeee-logo.svg`
        }
    }
}