import Vue from 'vue'
import CartPopin from '@/plugins/cartPopin'
import TWidget from '@/widget/widgetApp'

var TipeeeWidgets = (function () {
    const _identifiers = {
        box_tippers: 'tipeee-tippers-block',
        box_goal: 'tipeee-goal-block',
        card: 'tipeee-project-card',
        'small-card': 'tipeee-project-small',
        button: 'tipeee-project-btn',
        'cart-simple': 'tipeee-project-cart-simple',
        'cart-iframe': 'tipeee-project-cart-iframe'
    }
    var _instance
    var _widgetsContainers = {
        box_tippers: [],
        box_goal: [],
        card: [],
        'small-card': [],
        button: [],
        'cart-simple': [], 
        'cart-iframe': []
    }
    
    function _getWidgetsContainers () {
        for (const type in _identifiers) {
            document.querySelectorAll('.' + _identifiers[type] + ':not([data-tipeee-plugin-init])').forEach(function (element) {
                _widgetsContainers[type].push(element)
            })
        }
    }

    function _init () {
        Vue.use(CartPopin)

        _getWidgetsContainers()
        for (const type in _widgetsContainers) {
            _widgetsContainers[type].forEach((element) => {
                _constructWidget(type, element)
            })
        }
    }

    function _constructWidget (type, element) {
        if (type === 'cart-simple') {
            element.setAttribute('data-tipeee-plugin-init', 1)
            element.addEventListener('click', window.$tipeeePopin.simpleCartOpen)
            return
        }
        const regex = /https:\/\/([a-z]{2}).(sandbox-)?tipeee.(com|fr|dev)\/([^\/]*)/gm
        const parsedUrl = regex.exec(element.href)
        const contUuid = _uuid()
        const dataset = element.dataset
        const color = ['red', 'blue', 'yellow'].includes(dataset.color) ? dataset.color : 'red'

        let container = document.createElement('div')
        container.id = contUuid

        element.replaceWith(container)
        TWidget.init({
            el: contUuid,
            widgetType: type,
            project: parsedUrl[4],
            lang: parsedUrl[1],
            color: color,
            dataset: dataset
        })
    }

    function _uuid () {
        return 'axxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0
            let v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    }

    return {
        getInstance () {
            if (!_instance) {
                _instance = _init()
            }
            return _instance
        }
    }
})
TipeeeWidgets().getInstance()
