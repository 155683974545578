<template>
    <div class="tipeee-plugin-button-widget">
        <a target="_blank" class="tipeee-plugin-classic-button" :href="url" @click.prevent="onClick">{{ "tip!" | trans }}</a>
        <!-- <div class="tipeee-plugin-button-tippers">
            <span class="tipeee-plugin-primary-text">{{ getParameter('tipperNumber') | formatNumber }}</span>&nbsp;{{ 'tipeur(s)' | trans({"smart_count": getParameter('tipperNumber', 0)})}}
        </div> -->
    </div>
</template>
<script>
import ProjectMixin from '@/plugins/projectMixin'

export default {
    mixins: [ProjectMixin],
    computed: {
        url () {
            return `${this.getProjectUrl}/cart?originUrl=${window.location.href}&orientation=${this.dataset.orientation}&rewards=${this.dataset.rewards}`
        },
        projectCaption () {
            return this.$options.filters.trans('Soutenez %creator% sur Tipeee', {
                creator: `<span class="tipeee-plugin-primary-text">${this.$options.filters.entityTrans(this.project, 'name')}</span>`
            })
        }
    },
    methods: {
        onClick (event) {
            this.$cartPopin.open(event.target.href)
        }
    }
}
</script>