import Vue from 'vue'
import App from '@/components/widget.vue'

import TipeeeApi from '@/plugins/tipeeeApi'
import MediaCache from '@/plugins/filters/mediaCache'
import EntityTrans from '@/plugins/filters/entityTrans'
import { filters } from 'tipeee-node-plugin'

let TipeeeApiPlugin = {
    install(Vue, opt) {
        Vue.prototype.$tipeeeApi = TipeeeApi
    }
}

Vue.use(TipeeeApiPlugin)
Vue.filter('mediaCache', MediaCache)
Vue.filter('formatNumber', filters.formatNumber)

export default {
    init (options) {
        Vue.filter('entityTrans', EntityTrans(options.lang))
        Vue.filter('trans', filters.trans(options.lang, require('@/plugins/_polyglot/translations/messages.' + options.lang + '.json')))
        
        new Vue({
            el: `#${options.el}`,
            render: h => h(App, {
                props: {
                    type: options.widgetType,
                    slug: options.project,
                    lang: options.lang,
                    color: options.color,
                    dataset: options.dataset
                }
            })
        })
    }
}