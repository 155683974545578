<template>
    <div>
        <iframe
            :src="iframSrc"
            :style="`width: 1px; min-width: 100%; overflow: hidden; border: 1px solid #e8e8e8;border-radius: 20px;height:${iframeHeight}px;`"
            ref="iframe"
            loading="lazy"
            scrolling="no"
            :height="`${iframeHeight}`"
            frameborder="0"
        ></iframe>
    </div>
</template>
<script>
import ProjectMixin from '@/plugins/projectMixin'

export default {
    mixins: [ProjectMixin],
    computed: {
        iframSrc () {
            return this.getProjectUrl + `/cart?hostedIframe=1&originUrl=${window.location.href}&orientation=${this.dataset.orientation}&rewards=${this.dataset.rewards}`
        },
        iframeHeight () {
            if (this.dataset.rewards === '1') {
                if (this.dataset.orientation === 'line') {
                    return 715
                } else {
                    return 680
                }
            } else {
                return 420
            }
        }
    }
}
</script>
