import Vue from 'vue'

export default {
    iframe: null,
    install(Vue, opt) {
        Vue.prototype.$cartPopin = this
        window.$tipeeePopin = this
        window.addEventListener("message", this.onPostMessage.bind(this), false)

        let hostedUrl = new URL(window.location.href)
        if (hostedUrl.searchParams.has('tipeeeSuccess')) {
            this.onTipeeeSuccess(hostedUrl)
        }
    },
    onPostMessage (event) {
        if (!event.origin.endsWith(".tipeee.fr") && !event.origin.endsWith(".tipeee.com") && !event.origin.endsWith("sandbox-tipeee.dev")) {
            return
        }
        if (!["cartPopin", "nuxtLoaded"].includes(event.data.code)) {
            return
        }
        switch (event.data.code) {
            case "nuxtLoaded": 
                this.onNuxtLoaded(event)
                break
            case "cartPopin":
            default:
                this.open(event.data.params.url)
        }
    },
    onTipeeeSuccess (url) {
        let successUrl = decodeURIComponent(url.searchParams.get('tipeeeSuccess'))
        this.open(successUrl)
        url.searchParams.delete('tipeeeSuccess')
        history.pushState(null, document.title, url)
    },
    open (url) {
        if (this.iframe !== null) {
            return
        }
        document.body.classList.add('tipeee-overlay-opened')
        let overlay = document.createElement('div')
        let iframeContainer = document.createElement('div')
        let closeButtonContainer = document.createElement('div')
        let closeButton = document.createElement('a')
        closeButtonContainer.classList.add('tipeee-iframe-close-button-container')
        closeButton.innerHTML = 'X'
        closeButton.classList.add('tipeee-iframe-close-button')
        closeButtonContainer.appendChild(closeButton)
        this.iframe = document.createElement('iframe')
        overlay.classList.add('tipeee-overlay-wrapper')
        this.iframe.classList.add('tipeee-iframe')
        this.iframe.setAttribute('src', url)
        this.iframe.setAttribute('style', `width: 1px; min-width: 100%; overflow: hidden; height: ${this.calculateHeight()}px`)
        this.iframe.setAttribute('height', this.calculateHeight())
        iframeContainer.classList.add('tipeee-iframe-container')
        iframeContainer.setAttribute('style', `width: ${this.calculateWidth()} !important;`)
        iframeContainer.appendChild(closeButtonContainer)
        iframeContainer.appendChild(this.iframe)
        overlay.appendChild(iframeContainer)
        document.body.appendChild(overlay)
        document.querySelector('.tipeee-overlay-wrapper').addEventListener('click', this.close.bind(this))
    },
    onNuxtLoaded (event) {
        if (this.isSmallDevice()) {
            return
        }
        if (event.data.params.height && typeof event.data.params.height === 'number') {
            this.iframe.setAttribute('height', `${event.data.params.height}`)
            this.iframe.setAttribute('style', `width: 1px; min-width: 100%; overflow: hidden; height: ${event.data.params.height}px`)
        }
    },
    close () {
        document.querySelector('.tipeee-overlay-wrapper').remove()
        document.body.classList.remove('tipeee-overlay-opened')
        this.iframe = null
    },
    simpleCartOpen (e) {
        e.preventDefault()
        let link = e.target.closest('.tipeee-project-cart-simple')
        let url = link.href + `/cart?originUrl=${window.location.href}&orientation=${link.dataset.orientation}&rewards=${link.dataset.rewards}`
        window.$tipeeePopin.open(url)
        return false
    },
    calculateWidth () {
        let clientWidth = window.innerWidth
        return clientWidth <= 590 ? '100%' : '590px'
    },
    calculateHeight () {
        let clientHeight = window.innerHeight
        return clientHeight <= 900 ? window.innerHeight - 30 : 870 
    },
    isSmallDevice () {
        return window.innerWidth <= 590 || window.innerHeight <= 900
    }
}