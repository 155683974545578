export default (locale) => (entity, key, inLocale = null) => {
    if (!entity) {
        return null
    }
    if (!entity.translations) {
        return entity[key] || null
    }

    if (!inLocale) {
        inLocale = locale
    }

    let translation = null
    translation = entity.translations[inLocale] || entity.translations[inLocale + '_' + inLocale]
    if (!translation && entity.lang) {
        translation = entity.translations[entity.lang] || entity.translations[entity.lang + '_' + entity.lang]
    }
    if (!translation) {
        translation = entity.translations['fr'] || entity.translations['fr_fr']
    }
    if (!translation) {
        let lang = Object.keys(entity.translations)[0]
        translation = entity.translations[lang] || null
    }
    if (!translation || !translation[key]) {
        return entity[key] || null
    }
    return translation[key]
}