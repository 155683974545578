export default (media, options = {}) => {
    const defaultOptions = {
        original: false,
        width: 400,
        height: 400,
        avatar: null,
        fit: 'cover',
        mode: 'zoom'
    }
    options = {
        ...defaultOptions,
        ...options
    }
    const baseApi = `https://api.${process.env.TIPEEE_BASE_DNS}`
    if (!media || media == '0') {
        if (options.avatar) {
            return `${baseApi}/img/default_avatar_${parseInt(options.avatar) % 8}.png`
        }
        return baseApi + `/img/default-thumbnail.jpg`
    }
    let regex = /-|T|:/g
    let date = media.updated_at.split('+')[0].replace(regex, '')


    let path = `${baseApi}/${media.path}/${media.filename}`
    if (options.original) {
        return path
    }

    
    if (process.env.IMAGE_PROXY !== '0') {
        return `https://www.tipeeestream.com/cdn-cgi/image/onerror=redirect,width=${options.width},height=${options.height},fit=${options.fit}/${path}`
    } else {
        return `${baseApi}/cache/${date}/media/${options.width}/${options.height}/${options.mode}/${media.id}/${media.filename}`
    }
}
