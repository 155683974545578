<template>
    <div class="tipeee-plugins-widget" v-if="project">
        <Box class="tipeee-plugins-widget-wrapper" v-if="['box_tippers', 'box_goal'].includes(type)" :project="project" :tippers="tippers" :lang="lang" :mode="type === 'box_tippers' ? 'tippers' : 'goal'" :color="color" />
        <Button class="tipeee-plugins-widget-wrapper" v-if="type === 'button'" :project="project" :tippers="tippers" :lang="lang" />
        <Card class="tipeee-plugins-widget-wrapper" v-if="type === 'card'" :project="project" :tippers="tippers" :lang="lang" />
        <SmallCard class="tipeee-plugins-widget-wrapper" v-if="type === 'small-card'" :project="project" :tippers="tippers" :lang="lang" />
        <CartSimple class="tipeee-plugins-widget-wrapper" v-if="type === 'cart-simple'" :project="project" :tippers="tippers" :lang="lang" :dataset="dataset" />
        <CartIframe class="tipeee-plugins-widget-wrapper" v-if="type === 'cart-iframe'" :project="project" :tippers="tippers" :lang="lang" :dataset="dataset" />
    </div>
</template>
<script>
import Box from '@/components/widgets/box.vue'
import Button from '@/components/widgets/button.vue'
import Card from '@/components/widgets/card.vue'
import SmallCard from '@/components/widgets/smallCard.vue'
import CartSimple from '@/components/widgets/cart/simple.vue'
import CartIframe from '@/components/widgets/cart/iframe.vue'

export default {
    props: ['type', 'slug', 'lang', 'color', 'dataset'],
    components: { Box, Button, Card, SmallCard, CartSimple, CartIframe },
    data: () => ({
        project: null,
        tippers: null
    }),
    async created () {
        this.project = (await this.$tipeeeApi.generate('api_v2_get_project', { project: this.slug })).data
        if (this.type === 'box_tippers') {
            this.tippers = (await this.$tipeeeApi.generate('api_v2_get_projects_top_tippers', {
                project: this.slug,
                perPage: 12,
                page: 1
            })).data.items
        }
    }
}
</script>
<style src="@/assets/styles/main.less">