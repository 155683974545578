<template>
    <div class="tipeee-plugin-radius-block tipeee-plugin-small-padding tipeee-plugin-background-white tipeee-plugin-card-widget">
        <div class="tipeee-plugin-card-content">
            <a :href="getProjectUrl" class="tipeee-plugin-card-avatar" target="_blank" :style="{ 'background-image': `url(${$options.filters.mediaCache(project.avatar ? project.avatar : 0, {width: 180, height: 180, avatar: project.id})})` }"></a>
            <div class="tipeee-plugin-card-caption" v-html="projectCaption"></div>
        </div>
        <div class="tipeee-plugin-card-footer">
            <a :href="getSiteUrl" class="tipeee-plugin-card-logo" target="_blank"><img :src="tipeeeLogo" /></a>
            <div class="tipeee-plugin-card-footer-right">
                <div class="tipeee-plugin-cart-tippers">{{ ProjectService.getParameter(project, 'tipperNumber') | formatNumber }} {{ 'tipeur(s)' | trans({"smart_count": ProjectService.getParameter(project, 'tipperNumber', 0)})}}</div>
                <a :href="getProjectUrl" class="tipeee-plugin-card-button" target="_blank">tip!</a>
            </div>
        </div>
    </div>
</template>
<script>
import ProjectMixin from '@/plugins/projectMixin'
import tipeeeLogo from '@/assets/img/tipeee-logo.svg'

export default {
    mixins: [ProjectMixin],
    data: () => ({
        tipeeeLogo: tipeeeLogo
    }),
    computed: {
        projectCaption () {
            return this.$options.filters.trans('%creator% est sur Tipeee, le site #1 en Europe de Tip participatif !', {
                creator: `<span class="tipeee-plugin-primary-text">${this.$options.filters.entityTrans(this.project, 'name')}</span>`
            })
        }
    }
}
</script>